import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { GeneralInfo, InterlocutorsTabsModel, Introduction, Lookup, Mission } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { LookupDataService, LookupTypeId } from '../../services/lookup-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { IntroductionService } from '../../shared/components/forms/besoin-form/introduction.service';
import { GeneralInfoService } from '../../shared/components/forms/contact/contact-main-form/general-info.service';
import { additionalInfoViewModelToDataModel } from '../../shared/components/forms/info/info-form/additional-info.extensions';
import { AdditionalInfoService } from '../../shared/components/forms/info/info-form/additional-info.service';
import { stakeholdersViewModelToDataModel } from '../../shared/components/forms/info/info-other-form/stakeholders-form.extensions';
import { StakeholdersService } from '../../shared/components/forms/info/info-other-form/stakeholders.service';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent extends AbstractClientTabComponent implements OnInit {
  public form: FormGroup;
  public FormMode = FormMode;
  public profileGuid: string;
  public additionalInfoFormValid: boolean;
  public additionalInfoFormChangedModel: FormValuesChangedModel;
  public stakeholdersFormChangedModel: FormValuesChangedModel;
  public stakeholdersFormValid: boolean = true;

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
  public socialId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;
  public generalInfoModel: GeneralInfo;
  public introductionModel: Introduction;

  public maxLengthInfoText = constants.maxLengthInfoText;
  public address: string;
  public commercialName: string;
  public employeesNumber: string;
  public activityDescription: string;
  public activityStartDate: string;
  public missions: string;

  constructor(
    public route: ActivatedRoute,
    public tabsService: TabsService,
    public customerDataService: CustomerDataService,
    public progressBarService: ProgressBarService,
    public lookupDataService: LookupDataService,
    public stakeholdersService: StakeholdersService,
    public additionalInfoService: AdditionalInfoService,
    public introductionService: IntroductionService,
    public generalInfoService: GeneralInfoService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Interlocutors, bsModalService);
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.information.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.generalInfoModel = await this.generalInfoService.get(this.profileGuid);
    this.introductionModel = await this.introductionService.get(this.profileGuid);
    this.initSubscriptions();
    this.initNewFormGroup();
    this.restoreAnswers();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isInfoFormsValid === undefined) {
        return;
      }

      if ((this.isInterlocutorsPristine && this.isInfoFormsValid)) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isInfoFormsValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveInterlocutors(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  restoreAnswers() {
    this.commercialName = this.generalInfoModel.commercialName?.truncate(this.maxLengthInfoText);
    this.address = `${this.generalInfoModel.addressLine}, ${this.generalInfoModel.zipCode}, ${this.generalInfoModel.city}`.truncate(this.maxLengthInfoText);
    this.employeesNumber = this.introductionModel.employeesNumber ? `${this.introductionModel.employeesNumber} salarié(s)` : '';
    this.activityDescription = this.introductionModel.activityDescription?.truncate(this.maxLengthInfoText);
    this.activityStartDate = this.introductionModel.activityStartDate ? moment(this.introductionModel.activityStartDate).format(constants.dateFormat) : '';
    const missionsIds = this.introductionModel.customizedMissions?.map((mission: Mission) => {
      return mission.id;
    });
    const filtered = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].filter((item: Lookup) => {
      return missionsIds.indexOf(item.id) !== -1;
    })
    this.missions = filtered.map((item: Lookup) => {
      return item.name
    }).join(', ').truncate(this.maxLengthInfoText);
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
    });
  }

  goBack(): void {
    this.menuDataService.onChangeStep(ClientMenuStep.Besoin);
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();
    const response = await this.tabsService.saveInterlocutors(model);

    const customizedMissionsIds = this.customerDataService.introductionModelValue.customizedMissions?.map((m: Mission) => {
      return m.id;
    });

    if (customizedMissionsIds.indexOf(this.comptabiliteId) !== -1) {
      this.menuDataService.onChangeStep(ClientMenuStep.Volumes);
    } else if (customizedMissionsIds.indexOf(this.socialId) !== -1) {
      this.menuDataService.onChangeStep(ClientMenuStep.Social);
    } else {
      this.menuDataService.onChangeStep(ClientMenuStep.Files);
    }
  }

  prepareModelToSave(): InterlocutorsTabsModel {
    return {
      additionalInfo: additionalInfoViewModelToDataModel(this.additionalInfoService.config, this.additionalInfoFormChangedModel),
      stakeholders: stakeholdersViewModelToDataModel(this.stakeholdersService.config, this.stakeholdersFormChangedModel, this.lookupDataService),
      profileGuid: this.profileGuid
    } as InterlocutorsTabsModel;
  }

  onAdditionalInfoFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.additionalInfoFormValid = data.validState;
    })
    this.additionalInfoFormChangedModel = data;
  }

  onStakeholdersFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.stakeholdersFormValid = data.validState;
    })
    this.stakeholdersFormChangedModel = data;
  }

  get isInfoFormsValid(): boolean {
    return this.additionalInfoFormValid && this.stakeholdersFormValid;
    // return true;
  }

  get isInterlocutorsPristine(): boolean {
    return this.stakeholdersFormChangedModel?.isPristine && this.additionalInfoFormChangedModel?.isPristine;
  }
}
