import { DayMonthFormControl } from './../../../controls/day-month-form-control/day-month-form-control';
import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { DateFormControl } from './../../../controls/date-form-control/date-form-control';
import { NameFormControl } from './../../../controls/name-form-control/name-form-control';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { BsModalService } from 'ngx-bootstrap/modal';
import { formFields } from '../../../../constants/form-fields';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { AdditionalInfoService } from './additional-info.service';
import { AdditionalInfo, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { additionalInfoFormToViewModel, getAdditionalInfoFormComponentControl, getDayAndMonthFromString } from './additional-info.extensions';
import { AbstractForm } from '../../abstract-form';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { SelectedCompanyDataService } from 'projects/difference/app/services/selected-company-data.service';

@Component({
  selector: 'app-info-form',
  templateUrl: './info-form.component.html'
})
export class InfoFormComponent extends AbstractForm<AdditionalInfo> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public isAccountantEverHiredControl: RadioGroupFormControl;
  public currentAccountantCompanyNameControl: NameWithDigitsFormControl;
  public isCommitmentEndDateKnownControl: RadioGroupFormControl;
  public currentAccountantNameControl: NameFormControl;
  public commitmentEndDateControl: DateFormControl;
  public corporateAccountsClosingDateControl: DayMonthFormControl;
  public changes: SimpleChanges;

  constructor(
    public bsModalService: BsModalService,
    public additionalInfoService: AdditionalInfoService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService,
    public selectedCompanyDataService: SelectedCompanyDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.changes = changes;
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.additionalInfoService.getConfig();
    this.formData = await this.additionalInfoService.get(this.profileGuid);
    this.formId = this.formData?.additionalInfoId;
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getAdditionalInfoFormComponentControl(configItem, this.mode);

      if (controlToBeAdded) {
        this.form.addControl(configItem.name, controlToBeAdded);
      }
    })
  }

  restoreAnswers(): void {
    const viewModel = additionalInfoFormToViewModel(this.config, this.formData);
    this.form.patchValue(viewModel);
    this.checkAndRestoreSelectedCompanyData();
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  async checkAndRestoreSelectedCompanyData(): Promise<void> {
    if (this.selectedCompanyDataService.selectedCompanyExtraInformation) {
      if (this.corporateAccountsClosingDateControl.value === undefined || this.corporateAccountsClosingDateControl.value === null || this.corporateAccountsClosingDateControl.value === '') {
        const dateToSave = getDayAndMonthFromString(this.selectedCompanyDataService.selectedCompanyExtraInformation.financialYearClosingDate ?? '')
        this.corporateAccountsClosingDateControl.patchValue(dateToSave);
      }
    }
  }

  initControls(): void {
    this.isAccountantEverHiredControl = this.form.get(formFields.additionalInfo.isAccountantEverHired) as RadioGroupFormControl;
    this.currentAccountantCompanyNameControl = this.form.get(formFields.additionalInfo.currentAccountantCompanyName) as NameWithDigitsFormControl;
    this.currentAccountantNameControl = this.form.get(formFields.additionalInfo.currentAccountantName) as NameFormControl;
    this.isCommitmentEndDateKnownControl = this.form.get(formFields.additionalInfo.isCommitmentEndDateKnown) as RadioGroupFormControl;
    this.commitmentEndDateControl = this.form.get(formFields.additionalInfo.commitmentEndDate) as DateFormControl;
    this.corporateAccountsClosingDateControl = this.form.get(formFields.additionalInfo.corporateAccountsClosingDate) as DayMonthFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.isAccountantEverHiredControl?.valueChanges.subscribe((value: boolean) => {
      this.isCommitmentEndDateKnownControl.markRequiredOrNot(value);
      this.currentAccountantCompanyNameControl.markRequiredOrNot(value);
      this.currentAccountantNameControl.markRequiredOrNot(value);

      if (value === false) {
        this.commitmentEndDateControl.patchValue(null);
        this.currentAccountantCompanyNameControl.patchValue(null);
        this.isCommitmentEndDateKnownControl.patchValue(null);
        this.currentAccountantNameControl.patchValue(null);
      }
    });

    this.subscriptionHandler.subscriptions = this.isCommitmentEndDateKnownControl?.valueChanges.subscribe((value: boolean) => {
      this.commitmentEndDateControl.markRequiredOrNot(value);
    })
  }

  get isNew(): boolean {
    return !this.formData;
  }

  get isCertifiedAccountant(): boolean {
    return this.isAccountantEverHiredControl?.value === true;
  }

  get isCommitmentEndDateKnown(): boolean {
    return this.isCommitmentEndDateKnownControl?.value === true;
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }
}
