import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BulkOperationResult } from 'projects/difference/webapi/Difference.WebApi';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeleteErrorModalComponent } from '../components/devis/modals/delete-error-modal/delete-error-modal.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private bsModalService: BsModalService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 409: {
                            this.processingError(err);

                            return throwError(err);
                        }
                        default: {
                            return throwError(err);
                        }
                    }
                } else {
                    return throwError(err);
                }
            })
        );
    }

    private processingError(err: any): void {
        if (err) {
            err.error.text().then((text: string) => {
                const data = JSON.parse(text) as BulkOperationResult;
                this.showErrorModal(data)
            })
        }
    }

    private showErrorModal(data: BulkOperationResult) {
        if (data?.failedOperations?.length > 0) {

            const blockedBy = data?.failedOperations[0].errorMessage;

            this.bsModalService.show(DeleteErrorModalComponent, {
                initialState: {
                    body: `La suppression de ce(s) élément(s) ne peut pas être réalisée car ils sont en cours d'utilisation par la ressource suivante : ${blockedBy}. <br/><br/> 
              Supprimez cette dépendance puis essayez à nouveau.`
                },
                class: 'modal-dialog-centered'
            });
        }
    }
}
