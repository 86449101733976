import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function matDatepickerMaxValidator() {
    return (control: FormControl) => {
        const date = control.value;
        const maxDate = control.get('maxDate'); 

        if (date && maxDate) {
            if (moment(date) > moment(maxDate.value)) {
                return {
                    matDatepickerMax: true
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
