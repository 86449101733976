<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div *ngFor="let contactsForm of contacts?.controls; index as i">
    <ng-container [formGroup]="contactsForm">
      <div class="row">
        <div class="col title-col subsection" [class.mt-3]="!isEditMode && i > 0">
          <h5>Contact {{ i > 0 ? i + 1 : '' }}</h5>
          <img *ngIf="i !== 0 && isEditMode" src="../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteContact(i)" />
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.contactSecondFormComponent.contactRole, config)">
        <div class="col-12">
          <app-dropdown-form-control
            [parentForm]="contactsForm"
            [controlName]="getControlName(formFields.contactSecondFormComponent.contactRole, config)"
            [title]="'Rôle au sein de l\'entité'"
            [placeholder]="'Saisissez le rôle du contact au sein de l\'entité'"
            [floatLabel]="'always'"
          >
          </app-dropdown-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.contactSecondFormComponent.civility, config)">
        <div class="col-12">
          <app-dropdown-form-control
            [parentForm]="contactsForm"
            [controlName]="getControlName(formFields.contactSecondFormComponent.civility, config)"
            [title]="'Civilité'"
            [placeholder]="'Sélectionnez la civilité'"
          >
          </app-dropdown-form-control>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.contactSecondFormComponent.lastName, config)">
          <app-name-form-control
            [parentForm]="contactsForm"
            [controlName]="getControlName(formFields.contactSecondFormComponent.lastName, config)"
            [title]="'Nom'"
            [placeholder]="'Saisissez le nom du contact'"
          >
          </app-name-form-control>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.contactSecondFormComponent.firstName, config)">
          <app-name-form-control
            [parentForm]="contactsForm"
            [controlName]="getControlName(formFields.contactSecondFormComponent.firstName, config)"
            [title]="'Prénom'"
            [placeholder]="'Saisissez le prénom du contact'"
          >
          </app-name-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div *ngFor="let extraPhoneForm of getPhonesFormArray(contactsForm).controls; index as j" class="row">
            <ng-container [formGroup]="extraPhoneForm">
              <div [ngClass]="{ 'col-12': j == 0, 'col-10 col-sm-11 col-md-10': j != 0 }">
                <label class="common-title-label outside-row">Numéro de téléphone <span *ngIf="isEditMode">*</span></label>
                <div class="row">
                  <div class="col-4 col-sm-5 col-md-4 col-lg-5">
                    <app-dropdown-form-control
                      [parentForm]="extraPhoneForm"
                      (onChange)="onPhoneTypeChanged(extraPhoneForm)"
                      [controlName]="'phoneType'"
                      [title]="''"
                      [placeholder]="'Type'"
                      class="no-title"
                    >
                    </app-dropdown-form-control>
                  </div>
                  <div class="col-8 col-sm-7 col-md-8 col-lg-7">
                    <app-phone-form-control [parentForm]="extraPhoneForm" [controlName]="'phoneNumber'" [title]="''" class="no-title"> </app-phone-form-control>
                  </div>
                </div>
              </div>
              <div *ngIf="j !== 0" [ngClass]="{ 'col-2 col-sm-1 col-md-2 col-lg-2': j != 0 }">
                <img *ngIf="j !== 0 && isEditMode" src="../../../../../assets/img/delete-icon.svg" class="icon-in-col red" alt="" (click)="deleteExtraPhone(contactsForm, j)" />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <app-email-form-control
            [parentForm]="contactsForm"
            [controlName]="getControlName(formFields.contactSecondFormComponent.email, config)"
            [title]="'Adresse email'"
            [placeholder]="'Saisissez l\'adresse email'"
          >
          </app-email-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isEditMode">
        <div class="col-sm-12 col-md-12 col-lg-6 text-center">
          <button class="btn pink w100 one-in-col" (click)="addNewPhone(contactsForm)">
            <img src="../../../../../assets/img/plus-icon.svg" alt="" />
            Ajouter un numéro
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row" *ngIf="isEditMode">
    <div class="col-sm-12 col-md-12 col-lg-6 text-center">
      <button class="btn pink w100" (click)="addNewContact()">
        <img src="../../../../../assets/img/plus-icon.svg" alt="" />
        Ajouter un contact
      </button>
    </div>
  </div>
</div>
