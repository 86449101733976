import { ValidatorFn } from '@angular/forms';
import { collections } from '../../../constants/collections';
import { AbstractFormControl } from '../abstract-form-control';

export class DropDownFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private collectionDescription: CollectionDescription = {}, private options?: DropDownOptions) {
        super(required, disabled);

        if (options && (options.defaultValue || options.defaultValue === 0)) {
            this.setValue(options.defaultValue);
        } else {
            this.setValue(null);
        }

        if (collectionDescription.collection) {
            this.setCollection(collectionDescription.collection);
        }
    }

    markRequiredOrNot(isRequired: boolean) {
        if (isRequired) {
            this.markAsRequred();
        } else {
            this.markAsNotRequred();
        }
    }

    markAsRequred(): void {
        super.markAsRequred();
    }

    markAsNotRequred(): void {
        super.markAsNotRequred();
    }

    setCollection(collection: any[]) {
        this.collectionDescription.collection = collection;

        if (this.collectionDescription.hiddenOptions) {
            const foundOption = this.collectionDescription.collection.find((item: any) => item[this.keyPropertyName] === this.collectionDescription.hiddenOptions[0][this.keyPropertyName]);
            if (foundOption) {
                this.collectionDescription.hiddenOptions = null;
            }
        }

        if (!this.value && this.collectionDescription.enableFirstChooseOption && this.collectionDescription.collection?.length) {
            this.setValue(this.collectionDescription.collection[0][this.collectionDescription.keyPropertyName]);
        }
    }

    removeFirstChooseOption(): void {
        if (this.collectionDescription) {
            this.setValue(null);
            this.collectionDescription.enableFirstChooseOption = false;
        }
    }

    addFirstChooseOption(): void {
        if (this.collectionDescription) {
            this.collectionDescription.enableFirstChooseOption = true;
            this.setCollection(this.collectionDescription.collection);
        }
    }

    setHiddenOption(option: any): void {
        if (this.collectionDescription) {
            if (this.collectionDescription.collection) {
                const foundOption = this.collectionDescription.collection.find((item: any) => item[this.keyPropertyName] === option[this.keyPropertyName]);
                if (!foundOption) {
                    this.collectionDescription.hiddenOptions = [option];
                }
            } else {
                this.collectionDescription.hiddenOptions = [option];
            }
        }
    }

    get collection(): any[] {
        return this.collectionDescription.collection;
    }

    get hiddenOptions(): any {
        return this.collectionDescription.hiddenOptions;
    }

    get keyPropertyName(): string {
        return this.collectionDescription.keyPropertyName;
    }

    get valuePropertyName(): string {
        return this.collectionDescription.valuePropertyName;
    }

    get disableChooseOption(): boolean {
        return this.collectionDescription.disableChooseOption;
    }

    get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark || true;
    }

    get hideErrorMessages(): boolean {
        return this.options?.hideErrorMessages || true;
    }

    get hideErrorMark(): boolean {
        return this.options?.hideErrorMark || true;
    }

    get matOptionAutoHeight(): boolean {
        return this.options?.matOptionAutoHeight;
    }

    get requiredErrorMessage(): string {
        if (this.options && this.options.requiredErrorMessage) {
            return this.options.requiredErrorMessage;
        } else {
            return collections.messages.required;
        }
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}

export interface CollectionDescription {
    keyPropertyName?: string;
    valuePropertyName?: string;
    collection?: any[];
    disableChooseOption?: boolean;
    enableFirstChooseOption?: boolean;
    withTooltip?: boolean;
    hiddenOptions?: any[];
}

export interface DropDownOptions {
    defaultValue?: any;
    readOnlyCss?: boolean;
    hideSuccessMark?: boolean;
    hideErrorMessages?: boolean;
    hideErrorMark?: boolean;
    requiredErrorMessage?: string;
    matOptionAutoHeight?: boolean;
}
