import { DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { map } from 'rxjs/operators';

export abstract class AbstractFormService<T> {
    public config: DataFieldConfig[];
    public data: T;

    constructor(public webApi: any) { }

    public async getConfig(): Promise<DataFieldConfig[]> {
        if (this.config) {
            return new Promise(resolve => {
                resolve(this.config);
            });
        } else {
            return this.webApi.getConfig().pipe(map((response: DataFieldConfig[]) => {
                // response.map((dataFieldConfig: DataFieldConfig) => { // make all as required
                //     dataFieldConfig.isRequired = true;
                //     return dataFieldConfig;
                // })

                this.config = response;
                return response;
            })).toPromise();
        }
    }

    public async get(userId?: string): Promise<T | null> {
        if (!userId) {
            return new Promise((resolve) => {
                resolve(null);
            })
        }
        return this.webApi.get(userId).pipe(map((response: T) => {
            this.data = response;
            return response;
        })).toPromise();
    }

    public async save(model: T): Promise<T | null> {
        return this.webApi.save(model).pipe(map((response: T) => {
            this.data = response;
            return response;
        })).toPromise();
    }

    public async saveByProfileGuid(profileGuid: string, model: T): Promise<T | null> {
        return this.webApi.save(profileGuid, model).pipe(map((response: T) => {
            this.data = response;
            return response;
        })).toPromise();
    }
}
