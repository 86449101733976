import { Directive, EventEmitter, OnChanges, Output, SimpleChanges, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from '../../constants/form-fields';
import { SubscriptionHandler } from '../subscriptionHandler';

@Directive()
export abstract class AbstractForm<T> implements OnChanges, OnDestroy {
    @Input() profileGuid: string;
    @Output() onFormValuesChanged: EventEmitter<FormValuesChangedModel> = new EventEmitter();
    @Output() onFormDataLoaded: EventEmitter<boolean> = new EventEmitter();

    public mode: FormMode;
    public form: FormGroup;
    public formId: any;
    public hasCompany: boolean;

    public formFields = formFields;
    public config: DataFieldConfig[];
    public formData: T;
    public prevFormDataChanges: any;

    public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue) { // switch to edit mode in the admin part
            if (this.prevFormDataChanges) {
                this.notifyAboutModelChanges();
            }
        }

        this.subscriptionHandler.subscriptions = this.form?.valueChanges.subscribe((newDataChanges: any) => {
            if (JSON.stringify(this.prevFormDataChanges) !== JSON.stringify(newDataChanges)) {
                this.notifyAboutModelChanges();
            }

            this.prevFormDataChanges = Object.assign(newDataChanges);
        });
        this.form?.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler?.unsubscribeAll();
    }

    private notifyAboutModelChanges() {
        const model = this.form.getRawValue();
        const validState = this.form.valid;

        this.onFormValuesChanged.emit(
            {
                model: model,
                validState: validState,
                isPristine: this.form.pristine === undefined ? true : this.form.pristine,
                formId: this.formId,
                profileGuid: this.profileGuid
            });
    }

    public getControlName(name: string, config: DataFieldConfig[]): string {
        const item = config.filter((item: DataFieldConfig) => {
            return item.name === name;
        })[0];

        return item.name;
    }

    public getControlRequiredState(name: string, config: DataFieldConfig[]): boolean {
        const item = config.filter((item: DataFieldConfig) => {
            return item.name === name;
        })[0];

        return item.isRequired;
    }

    public isShowControl(name: string, config: DataFieldConfig[]): boolean {
        return !!config.filter((item: DataFieldConfig) => {
            return item.name === name;
        })[0];
    }

    public getIsControlRequired(name: string): boolean {
        const item = this.config?.filter((item: DataFieldConfig) => {
            return item.name === name;
        })[0];

        return item?.isRequired;
    }

    get isEditMode(): boolean {
        return this.mode === FormMode.Edit;
    }

    get showControlsWithErrors(): string {
        let res = '';

        Object.keys(this.form.controls).forEach(key => {
            if (this.form.controls[key].errors) {
                res += `${key}, `;
            }
        });

        return res;
    }

}

export class FormValuesChangedModel {
    model: any;
    validState: boolean;
    isPristine?: boolean;
    formId: any;
    profileGuid: any;
}
