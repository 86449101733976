import { LookupTypeId } from './../../../../../services/lookup-data.service';
import { DataFieldConfig, GeneralInfo } from 'projects/difference/webapi/Difference.WebApi';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { NameFormControl } from '../../../controls/name-form-control/name-form-control';
import { formFields } from '../../../../constants/form-fields';
import { CheckboxFormControl } from '../../../controls/checkbox-form-control/checkbox-form-control';
import { SirenFormControl } from '../../../controls/siren-form-control/siren-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { ZipCodeFormControl } from '../../../controls/zipcode-form-control/zipcode-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../../abstract-form';
import { ExtraGeneralInfoData } from 'projects/difference/app/components/contact/contact.component';
import { CompanyNameAutocompleteFormControl } from '../../../controls/company-name-autocomplete/company-name-autocomplete-form-control';
import { AutocompleteFormControl } from '@controls/autocomplete-form-control/autocomplete-form-control';

function getContactMainFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.contactMainForm.isCompany: {
            return new CheckboxFormControl(config.isRequired, isReadOnly);
        }
        case formFields.contactMainForm.jurTypeId: {
            return new AutocompleteFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.Civilities],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.contactMainForm.firstName: {
            return new CompanyNameAutocompleteFormControl(config.isRequired, isReadOnly)
        }
        case formFields.contactMainForm.lastName: {
            return new NameFormControl(config.isRequired, isReadOnly)
        }
        case formFields.contactMainForm.commercialName: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly)
        }
        case formFields.contactMainForm.sirenNumber: {
            return new SirenFormControl(config.isRequired, isReadOnly)
        }
        case formFields.contactMainForm.addressLine: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly, { maxLength: constants.maxLength.address })
        }
        case formFields.contactMainForm.zipCode: {
            return new ZipCodeFormControl(config.isRequired, isReadOnly)
        }
        case formFields.contactMainForm.city: {
            return new NameFormControl(config.isRequired, isReadOnly, { maxLength: constants.maxLength.ville })
        }
        case formFields.contactMainForm.office: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                collection: [],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.contactMainForm.sourceInfoAboutUs: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.SourceInfoAboutUs],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.contactMainForm.partnerName: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.PartnerNames],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.contactMainForm.sourceInfoAboutUsOther: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly)
        }
    }
}

function contactMainFormToViewModel(config: DataFieldConfig[], data: any): any[] {
    const viewModel: any = {};

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
        viewModel[configItem.name] = data[propertyName];
    })

    return viewModel;
}

function contactsMainViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel, extra: ExtraGeneralInfoData): GeneralInfo {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            if (configItem.name === formFields.contactMainForm.firstName) {
                objModel[propertyNameForSaveObj] = model[configItem.name]['nom_entreprise'] ?? model[configItem.name]; // check is selected company object or not
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }
        })

        objModel.generalInfoId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    objModel.hasCompany = extra.hasCompany;
    objModel.isAcceptGdpr = extra.isAcceptGdpr;
    objModel.isAcceptNewsletter = extra.isAcceptNewsletter;

    return new GeneralInfo(objModel);
}

export {
    getContactMainFormComponentControl,
    contactMainFormToViewModel,
    contactsMainViewModelToDataModel
}
