import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NameAutocompleteFormControl } from './name-autocomplete-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import '../../../../shared/extentions/string.extensions';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-name-autocomplete-form-control',
    templateUrl: './name-autocomplete-form-control.component.html'
})
export class NameAutocompleteFormControlComponent extends AbstractFormControlComponent<NameAutocompleteFormControl> implements OnInit, OnChanges {
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

    @Output() onSelected: EventEmitter<any> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() placeholder: string;
    @Input() autocompleteOptions: any[] = [];
    @Input() hiddenOptions: any[] = [];
    @Input() useDisplayWith: boolean;
    @Input() showAllAfterInit: boolean;
    @Input() inline: boolean;

    faCheck: any = faCheck;

    filteredOptions: Observable<any[]>;
    autocompleteControl: NameAutocompleteFormControl;

    ngOnInit(): void {
        this.autocompleteControl = this.parentForm.get(this.controlName) as NameAutocompleteFormControl;

        if (this.showAllAfterInit) {
            setTimeout(() => {
                this.autocompleteControl.patchValue('');
                setTimeout(() => {
                    this.inputAutoComplete.openPanel();
                    this.autocompleteControl.updateValueAndValidity();
                }, 1);
            })
        }

        this.initFilteredOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.autocompleteOptions?.currentValue && JSON.stringify(changes.autocompleteOptions.currentValue) !== JSON.stringify(changes.autocompleteOptions.previousValue)) {
            this.initFilteredOptions();
        }
    }

    initFilteredOptions(): any {
        this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
            startWith(''),
            map((state: any) => {
                if (this.autocompleteControl.untouched && this.showAllAfterInit) {
                    setTimeout(() => {
                        this.autocompleteControl.markAsTouched();
                    })
                    return [];
                }
                return this._filterOptions(state);
            })
        );
    }

    inputChanged(event: any): void {
        this.initFilteredOptions();
        if (!this.allowCustomText) {
            this.parentForm.get(this.controlName).patchValue(null);
        }
        this.onSelected.emit(this.control.value);
    }

    itemSelected(event: any): void {
        this.parentForm.get(this.controlName).patchValue(event.option.value);
        this.onSelected.emit(this.control.value);
    }

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get placeholderText(): string {
        return this.placeholder || this.title;
    }

    public get maxLength(): number {
        return this.control.maxLength;
    }

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public get autocompleteLabelField(): string {
        return this.control.autocompleteLabelField ?? 'name';
    }

    public get autocompleteValueField(): string {
        return this.control.autocompleteValueField ?? 'id';
    }

    public get allowCustomText(): boolean {
        return this.control.allowCustomText ?? false;
    }

    public get escapeFirstDigits(): boolean {
        return this.control.escapeFirstDigits;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }

    public displayWith(id: string | number): string {
        return this.autocompleteOptions.concat(this.hiddenOptions ?? []).find(option => `${option?.[this.autocompleteValueField]}` === `${id}`)?.[this.autocompleteLabelField];
    }

    private _filterOptions(value: string | number): any[] {
        return this.autocompleteOptions?.filter(item => item[this.autocompleteLabelField]?.toLowerCase().includes(`${value}`?.toLowerCase()));
    }
}
