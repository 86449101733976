import { CompanyNameAutocompleteFormControlComponent } from './controls/company-name-autocomplete/company-name-autocomplete-form-control.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContactMainFormComponent } from './forms/contact/contact-main-form/contact-main-form.component';
import { ContactSecondFormComponent } from './forms/contact/contact-second-form/contact-second-form.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxFormControlComponent } from './controls/checkbox-form-control/checkbox-form-control.component';
import { DropDownFormControlComponent } from './controls/dropdown-form-control/dropdown-form-control.component';
import { NameFormControlComponent } from './controls/name-form-control/name-form-control.component';
import { DirectivesModule } from '../directives/directives.module';
import { SirenFormControlComponent } from './controls/siren-form-control/siren-form-control.component';
import { NgxMaskModule } from 'ngx-mask';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipModule } from 'primeng/tooltip';
import { NameWithDigitsFormControlComponent } from './controls/name-with-digits-form-control/name-with-digits-form-control.component';
import { ZipCodeFormControlComponent } from './controls/zipcode-form-control/zipcode-form-control.component';
import { PhoneFormControlComponent } from './controls/phone-form-control/phone-form-control.component';
import { EmailFormControlComponent } from './controls/email-form-control/email-form-control.component';
import { PipesModule } from '../pipes/pipes.module';
import { DeleteConfirmComponent } from './modals/delete-confirm/delete-confirm.component';
import { DateFormControlComponent } from './controls/date-form-control/date-form-control.component';

import { NumberFormControlComponent } from './controls/number-form-control/number-form-control.component';
import { NameAutocompleteFormControlComponent } from './controls/name-autocomplete-form-control/name-autocomplete-form-control.component';
import { CheckboxListFormControlComponent } from './controls/checkbox-list-form-control/checkbox-list-form-control.component';
import { MatListModule } from '@angular/material/list';
import { InfoFormControlComponent } from './controls/info-form-control/info-form-control.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToogleFormControlComponent } from './controls/toogle-form-control/toogle-form-control.component';
import { InfoFormComponent } from './forms/info/info-form/info-form.component';
import { RadioGroupFormControlComponent } from './controls/radio-group-form-control/radio-group-form-control.component';
import { BenificiariesFormComponent } from './forms/benificiaries-form/benificiaries-form.component';
import { ComboBoxFormControlComponent } from './controls/combobox-form-control/combobox-form-control.component';
import { VentesPrestationFormComponent } from './forms/volumes/ventes-prestation-form/ventes-prestation-form.component';
import { AchatsFormComponent } from './forms/volumes/achats-form/achats-form.component';
import { NotesDeFraisFormComponent } from './forms/volumes/notes-de-frais-form/notes-de-frais-form.component';
import { AutresFluxFormComponent } from './forms/volumes/autres-flux-form/autres-flux-form.component';
import { AutresOutilsFormComponent } from './forms/volumes/autres-outils-form/autres-outils-form.component';
import { BanquesFormComponent } from './forms/volumes/banques-form/banques-form.component';
import { MainTaxesFormComponent } from './forms/taxes/main-taxes-form/main-taxes-form.component';
import { DebDesFormComponent } from './forms/taxes/deb-des-form/deb-des-form.component';
import { BesoinFormComponent } from './forms/besoin-form/besoin-form.component';
import { InfoOtherFormComponent } from './forms/info/info-other-form/info-other-form.component';
import { ControlCenterFormComponent } from './forms/taxes/control-center-form/control-center-form.component';
import { FeaturesOfTaxationFormComponent } from './forms/taxes/features-of-taxation-form/features-of-taxation-form.component';
import { SocialTopFormComponent } from './forms/social/social-top-form/social-top-form.component';
import { SocialMiddleFormComponent } from './forms/social/social-middle-form/social-middle-form.component';
import { SocialBottomFormComponent } from './forms/social/social-bottom-form/social-bottom-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OrganisationComptableFormComponent } from './forms/accounting/organisation-comptable-form/organisation-comptable-form.component';
import { FilesFormComponent } from './forms/files/files-form/files-form.component';
import { SharedAdminModule } from 'projects/difference-admin/app/shared/shared-admin.module';
import { DataTableComponent } from './data-table/data-table/data-table.component';
import { DatatableSearchComponent } from './data-table/data-table-search/data-table-search.component';
import { BaseSearchComponent } from './data-table/base-search-component/base-search.component';
import { DataTablesModule } from 'angular-datatables';
import { CriterionPipe } from './data-table/pipes/criterion.pipe';
import { SearchOperatorPipe } from './data-table/pipes/search-operator.pipe';
import { ClientSideDataTableComponent } from './data-table/client-side-data-table/client-side-data-table.component';
import { FilesEventsService } from './forms/files/files-form/files-events.service';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FileUploaderComponent } from './controls/file-uploader/file-uploader.component';
import { MobileFormControlComponent } from './controls/mobile-form-control/mobile-form-control.component';
import { SearchValuePipe } from './data-table/pipes/search-value.pipe';
import { MatIconModule } from '@angular/material/icon';
import { DayMonthFormControlComponent } from './controls/day-month-form-control/day-month-form-control.component';
import { ProspectsAutocompleteFormControlComponent } from './controls/prospects-autocomplete-form-control/prospects-autocomplete-form-control.component';
import { SimpleTooltipComponent } from './simple-tooltip/simple-tooltip.component';
import { AutocompleteFormControlComponent } from '@controls/autocomplete-form-control/autocomplete-form-control.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    ContactMainFormComponent,
    ContactSecondFormComponent,
    CheckboxFormControlComponent,
    DropDownFormControlComponent,
    NameFormControlComponent,
    SirenFormControlComponent,
    TooltipComponent,
    NameWithDigitsFormControlComponent,
    ZipCodeFormControlComponent,
    PhoneFormControlComponent,
    MobileFormControlComponent,
    EmailFormControlComponent,
    DeleteConfirmComponent,
    BesoinFormComponent,
    DateFormControlComponent,
    DayMonthFormControlComponent,
    NumberFormControlComponent,
    CheckboxListFormControlComponent,
    InfoFormControlComponent,
    ToogleFormControlComponent,
    InfoFormComponent,
    InfoOtherFormComponent,
    RadioGroupFormControlComponent,
    BenificiariesFormComponent,
    ComboBoxFormControlComponent,
    VentesPrestationFormComponent,
    AchatsFormComponent,
    NotesDeFraisFormComponent,
    AutresFluxFormComponent,
    AutresOutilsFormComponent,
    BanquesFormComponent,
    MainTaxesFormComponent,
    DebDesFormComponent,
    ControlCenterFormComponent,
    FeaturesOfTaxationFormComponent,
    OrganisationComptableFormComponent,
    SocialTopFormComponent,
    SocialMiddleFormComponent,
    SocialBottomFormComponent,
    NameAutocompleteFormControlComponent,
    FilesFormComponent,
    DataTableComponent,
    BaseSearchComponent,
    DatatableSearchComponent,
    CriterionPipe,
    SearchOperatorPipe,
    SearchValuePipe,
    ClientSideDataTableComponent,
    FileUploaderComponent,
    CompanyNameAutocompleteFormControlComponent,
    ProspectsAutocompleteFormControlComponent,
    SimpleTooltipComponent,
    AutocompleteFormControlComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    PipesModule,
    MatListModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    DataTablesModule,
    SharedAdminModule,
    FileUploadModule,
    MatIconModule
  ],
  exports: [
    SpinnerComponent,
    ContactMainFormComponent,
    ContactSecondFormComponent,
    CheckboxFormControlComponent,
    DropDownFormControlComponent,
    NameFormControlComponent,
    SirenFormControlComponent,
    TooltipComponent,
    NameWithDigitsFormControlComponent,
    ZipCodeFormControlComponent,
    PhoneFormControlComponent,
    MobileFormControlComponent,
    EmailFormControlComponent,
    DeleteConfirmComponent,
    BesoinFormComponent,
    DateFormControlComponent,
    DayMonthFormControlComponent,
    NumberFormControlComponent,
    CheckboxListFormControlComponent,
    InfoFormControlComponent,
    ToogleFormControlComponent,
    InfoFormComponent,
    InfoOtherFormComponent,
    RadioGroupFormControlComponent,
    BenificiariesFormComponent,
    ComboBoxFormControlComponent,
    VentesPrestationFormComponent,
    AchatsFormComponent,
    NotesDeFraisFormComponent,
    AutresFluxFormComponent,
    AutresOutilsFormComponent,
    BanquesFormComponent,
    MainTaxesFormComponent,
    DebDesFormComponent,
    ControlCenterFormComponent,
    FeaturesOfTaxationFormComponent,
    OrganisationComptableFormComponent,
    SocialTopFormComponent,
    SocialMiddleFormComponent,
    SocialBottomFormComponent,
    NameAutocompleteFormControlComponent,
    FilesFormComponent,
    DataTableComponent,
    BaseSearchComponent,
    DatatableSearchComponent,
    CriterionPipe,
    SearchOperatorPipe,
    SearchValuePipe,
    ClientSideDataTableComponent,
    FileUploaderComponent,
    CompanyNameAutocompleteFormControlComponent,
    ProspectsAutocompleteFormControlComponent,
    SimpleTooltipComponent,
    AutocompleteFormControlComponent
  ],
  providers: [
    FilesEventsService
  ]

})

export class SharedComponentsModule { }
